/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { socialLinks } from "../../constansts";

const Footer = () => {
	return (
		<div className="flex flex-col border border-t-[#666] bg-[#1E1E1E]">
			<div className="px-[50px] pt-[30px] pb-[20px] flex flex-col md:flex-row md:justify-between md:items-start md:gap-[42px]">
				{/* Social Media Section */}
				<div className="flex-1 mb-[30px] md:mb-0">
					<p className="text-white-txt mb-[12px]">Social Media</p>
					<div className="text-[#6A6B6D] flex flex-col gap-[12px]">
						{socialLinks.map((link) => (
							<Link to={link?.link} key={link.name} target="_blank">
								<p>{link?.name}</p>
							</Link>
						))}
					</div>
				</div>

				{/* Help Section */}
				<div className="flex-1 mb-[30px] md:mb-0">
					<p className="text-white-txt mb-[12px]">Help</p>
					<div className="text-[#6A6B6D] flex flex-col gap-[12px]">
						<Link>
							<p>FAQs</p>
						</Link>
						<Link>
							<p>Contact Support</p>
						</Link>
					</div>
				</div>

				{/* Legal Section */}
				<div className="flex-1">
					<p className="text-white-txt mb-[12px]">Legal</p>
					<div className="text-[#6A6B6D] flex flex-col gap-[12px]">
						<p>Terms and Conditions</p>
						<p>Privacy Policy</p>
					</div>
				</div>
			</div>

			{/* Copyright Section */}
			<p className="text-[#6A6B6D] text-[18px] font-[600] py-[20px] text-center">
				Copyright © 2024 Yaanpedia Technology Private Limited. All rights reserved.
			</p>
		</div>
	);
};

export default Footer;
