/** @format */

import React from "react";
import { solutionData } from "../../constansts";
import SolutionCard from "./SolutionCard";

const SolutionMain = () => {
	return (
		<div className="flex flex-col py-[50px] px-[32px] justify-center items-center ">
			<p className="font-[600] text-[32px] text-[#D4D5D8] text-center">
				Tailored Solutions for Every Fleet
			</p>
			<div className="md:flex md:flex-row md:gap-4">
				{solutionData?.map((solution) => (
					<SolutionCard {...solution} />
				))}
			</div>
		</div>
	);
};

export default SolutionMain;

