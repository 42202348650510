/** @format */

import React from "react";
import Button from "../Button";

const SolutionCard = (props) => {
	const { title, img } = props;

	return (
		<div
			className="bg-light rounded-[8px] mt-[24px] p-4 gap-3 flex flex-col justify-between"
			key={title}
		>
			<div className="flex flex-row items-center justify-center">
				<img src={`/assets/${img}`} alt={img} />
			</div>
			<p className="text-[16px] text-[#808991] text-start">{title}</p>
			<Button text="Request a Demo" />
		</div>
	);
};

export default SolutionCard;
