/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Button = ({ text, bgColor, link, className, type }) => {
	return (
		<Link to={link}>
			<button
				type={type ?? "button"}
				className={`${className} text-dark p-[10px] rounded-[6px] mt-[32px]`}
				style={{
					background: `${
						bgColor ?? "linear-gradient(101deg, #CEEF4C 4.69%, #E7FF8F 31.24%)"
					}`,
				}}
			>
				{text}
			</button>
		</Link>
	);
};

export default Button;
