/** @format */

import React from "react";
import Layout from "../../components/Layout";
import Title from "../../components/Title";
import ContactUs from "../../components/ContactUs";
import Pricing from "../../components/Pricing";

function PricingPage() {
	return (
		<Layout>
			<Title>
				<div className="text-white-txt text-[24px] font-[600] text-center py-[24px]">
					PRICING
				</div>
			</Title>
			<Pricing />
			<ContactUs />
		</Layout>
	);
}

export default PricingPage;
