/** @format */

import React from "react";
import Footer from "./Footer";
import DesktopNav from "./Navbar/DesktopNav";
import MobileNav from "./Navbar/MobileNav";
import { useDeviceSize } from "react-device-sizes";

function Layout({ children }) {
	const { smDown } = useDeviceSize();
	return (
		<div>
			{smDown ? <MobileNav /> : <DesktopNav />}
			<div>{children}</div>
			<Footer />
		</div>
	);
}

export default Layout;
