/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { navItems } from "../../constansts";

const MobileNav = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	return (
		<div className="bg-white relative flex flex-row justify-between items-center h-[60px] p-[8px] px-[12px]">
			<div>
				<Link to="/">
					<img src="/assets/logo-half.png" alt="carvach_logo" />
				</Link>
			</div>
			<button onClick={() => setIsMenuOpen(!isMenuOpen)}>
				<img src="/assets/hamburger.png" alt="carvach_logo" />
			</button>
			{isMenuOpen && (
				<div className="bg-white flex flex-col absolute top-[60px] right-0  p-4 w-[60vw] gap-[20px]">
					{navItems?.map((item) => (
						<Link to={item?.link} key={item?.name}>
							{item?.name}
						</Link>
					))}
				</div>
			)}
		</div>
	);
};

export default MobileNav;
