/** @format */

import React from "react";

const HomeTitle = () => {
	return (
		<div className="relative min-h-screen flex flex-col items-center justify-center py-[60px] px-[32px]">
			{/* Video Background */}
			<video
				className="absolute top-0 left-0 w-full h-full object-cover z-0"
				autoPlay
				loop
				muted
				playsInline
			>
				<source src="/assets/background-video.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video>

			{/* Overlay content */}
			<div className="relative z-10 text-light flex flex-col items-center justify-center">
				<div
					style={{
						width: "100%",
						height: "100%",
						textAlign: "center",
						color: "#E9E9E9",
						fontSize: 52,
						fontFamily: "Inter",
						fontWeight: "500",
						wordWrap: "break-word",
					}}
				>
					Revolutionize Your Fleet Management<br />
					with Al enabled FleetOS
					<div
						style={{
							width: "100%",
							height: "100%",
							textAlign: "center",
							color: "#B6B6B7",
							fontSize: 14,
							fontFamily: "Inter",
							fontWeight: "600",
							wordWrap: "break-word",
						}}
					>
						Optimize performance, reduce downtime, and make data-driven
						decisions effortlessly with CarVach FleetOS
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeTitle;
