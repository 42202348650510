/** @format */

import React, { useEffect, useRef } from "react";
import { featureData } from "../../constansts";
import Button from "../Button";
import { useLocation } from "react-router-dom";

const FeatureContainer = () => {
	const { pathname } = useLocation();
	const containerRef = useRef(null);

	// Intersection Observer for fade-in effect
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("fadeIn");
					}
				});
			},
			{
				threshold: 0.1, // Trigger animation when 10% of the element is in view
			}
		);

		const elements = containerRef.current.querySelectorAll(".feature-item");
		elements.forEach((el) => observer.observe(el));

		return () => {
			elements.forEach((el) => observer.unobserve(el)); // Cleanup on component unmount
		};
	}, []);

	return (
		<div
			className="bg-[#fff] rounded-[20px] flex flex-col justify-center items-center px-[32px] py-[50px]"
			ref={containerRef}
		>
			<div className="text-[44px] text-center font-[600]">
				Why Choose CarVach FleetOS?
			</div>
			{featureData?.map((feature, index) => (
				<div
					key={index}
					className="feature-item flex flex-col mt-[20px] border border-[#E2E8F0] rounded-[8px] p-4 md:border-none md:flex-row md:items-center md:justify-between md:gap-[100px] md:even:flex-row-reverse opacity-0 transition-opacity duration-1000 ease-in-out"
				>
					<div className="md:w-[50%]">
						<img src={`/assets/${feature?.img}`} alt="img" />
					</div>
					<div className="md:flex md:flex-col">
						<p className="text-[24px] font-[600]">{feature?.title}</p>
						<p className="text-[16px] font-[500] text-grey-txt">
							{feature?.desc}
						</p>
						{pathname === "/features" && (
							<div className="text-[#808991] text-[16px]">
								<ul>
									{feature?.pointers?.map((point, idx) => (
										<li key={idx} className="list-disc ml-4">
											{point}
										</li>
									))}
								</ul>
							</div>
						)}
						<div className="flex flex-row gap-2">
							<Button text="Request a Demo" />
							<Button
								text="Know More"
								bgColor="linear-gradient(101deg, #B1B1B1 4.69%, #BFBFBF 131.24%)"
								link="/features"
							/>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default FeatureContainer;
