/** @format */

import React from "react";
import { pricingDetail } from "../../constansts";
import PricingCard from "./PricingCard";
import { useLocation } from "react-router-dom";

const Pricing = () => {
	const { pathname } = useLocation();
	return (
		<div
			className={`flex flex-col justify-center items-center ${
				pathname !== "/pricing" ? "mt-[48px]" : " border border-t-[#666]"
			} `}
		>
			{pathname !== "/pricing"}
			<p className="text-[#D4D5D8] text-[24px] font-[600] mt-[24px]">
				Flexible Pricing for Any Fleet Size
			</p>
			<div className="flex flex-col gap-4 px-[32px] mt-[24px] md:flex md:flex-row ">
				{pricingDetail?.map((pricing) => (
					<PricingCard {...pricing} />
				))}
			</div>
		</div>
	);
};

export default Pricing;
