/** @format */

import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Solution from "./pages/Solution";
import Feature from "./pages/Feature";
import PricingPage from "./pages/Pricing";
import ContactUsPage from "./pages/ContactUs";

function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/features" element={<Feature />} />
			<Route path="solution" element={<Solution />} />
			<Route path="/pricing" element={<PricingPage />} />
			<Route path="/contact-us" element={<ContactUsPage />} />
		</Routes>
	);
}

export default App;
