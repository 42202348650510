/** @format */

export const featureData = [
	{
		title: "Al-Driven Predictive Maintenance",
		img: "why-1.png",
		desc: "Prevent costly breakdowns by forecasting vehicle issues before they happen.",
		pointers: [
			"CarVach FleetOS uses artificial intelligence to predict maintenance needs before they become urgent. By analyzing vehicle performance data, such as engine temperature, mileage, and driving patterns, it can forecast potential issues.",
			"This reduces unexpected breakdowns and costly emergency repairs, allowing you to maintain your fleet more effectively and extend the lifespan of your vehicles.",
			"Proactive maintenance scheduling ensures that your fleet operates at peak efficiency, minimizing downtime and keeping your vehicles on the road longer.",
		],
	},
	{
		title: "Real-Time Data Integration",
		img: "why-2.png",
		desc: "Seamlessly gather and analyze real-time data from your telematics and IoT devices.",
		pointers: [
			"Stay ahead with real-time insights from your fleet.",
			"CarVach FleetOS integrates seamlessly with various telematics systems and IoT devices, aggregating data into one easy-to-use dashboard.",
			"This allows you to track everything from vehicle location to engine diagnostics in real time.",
			"By having all the crucial data at your fingertips, you can make informed decisions quickly, improving response times, increasing operational efficiency, and cutting down on unnecessary delays.",
		],
	},
	{
		title: "Automated KPI Generation",
		img: "why-3.png",
		desc: "No more manual KPI setups—our AI analyzes data and generates relevant performance indicators for you.",
		pointers: [
			"Tracking performance is made easier with CarVach FleetOS’s automated KPI generation feature.",
			"Instead of manually inputting data and calculating performance metrics, our AI analyzes all incoming data and automatically generates key performance indicators.",
			"This includes metrics such as fuel efficiency, maintenance costs, and driver safety.",
			"With instant KPI generation, you can assess your fleet's performance at any moment, ensuring you stay on track to meet your operational goals.",
		],
	},
	{
		title: "Driver Behavior Monitoring",
		img: "why-4.png",
		desc: "Enhance driver safety and performance with automated behavior analysis and fuel efficiency tracking.",
		pointers: [
			"Enhancing driver safety and efficiency is critical to effective fleet management.",
			"CarVach FleetOS monitors driver behavior, analyzing data such as acceleration, braking patterns, speed, and fuel consumption.",
			"By providing detailed reports, it enables fleet managers to identify risky driving behaviors, address safety concerns, and coach drivers for better performance.",
			"This feature can help reduce accidents, lower fuel consumption, and enhance overall driver accountability and efficiency.",
		],
	},
	{
		title: "Sustainability Insights",
		img: "why-5.png",
		desc: "Track your fleet's carbon footprint and make greener, more efficient decisions.",
		pointers: [
			"Sustainability is more than a buzzword; it's a strategic priority for modern fleets.",
			"CarVach FleetOS helps you reduce your environmental impact by providing insights into your fleet’s carbon footprint.",
			"The software tracks fuel consumption, emissions, and route efficiency, offering actionable recommendations to reduce fuel usage and minimize your fleet's environmental impact.",
			"These insights allow you to make greener choices, improve compliance with environmental regulations, and achieve long-term sustainability goals.",
		],
	},
];

export const solutionData = [
	{
		title: "For Fleet Operators",
		img: "sol-1.png",
		pointers: [
			"CarVach FleetOS is designed to meet the specific needs of fleet operators by automating tasks such as vehicle tracking, performance analysis, and maintenance scheduling",
			"With dynamic KPIs and real-time data, fleet operators can easily manage their vehicles, track driver performance, and predict when maintenance is required.",
			"By streamlining fleet management, you can focus more on growth while the software takes care of data analysis and fleet monitoring, ensuring optimal operational performance.",
		],
	},
	{
		title: "For Auto Finance & Leasing Companies",
		img: "sol-2.png",
		pointers: [
			"Leasing companies can benefit greatly from CarVach FleetOS by optimizing asset utilization.",
			"Our predictive analytics tool helps you keep track of vehicle usage, predict maintenance schedules, and reduce overall operational costs.",
			"By extending the lifespan of vehicles through timely maintenance, you can increase your return on investment and reduce downtime.",
			"The platform also provides insights into fleet usage trends, allowing you to make informed decisions about vehicle leasing and finance strategies.",
		],
	},
	{
		title: "For Logistics & Transportation Firms",
		img: "sol-3.png",
		pointers: [
			"Logistics and transportation companies thrive on efficiency and timely deliveries.",
			"CarVach FleetOS provides real-time vehicle monitoring, route optimization, and proactive maintenance scheduling to ensure your fleet is always in top condition.",
			"With real-time data at your disposal, you can make adjustments to routes, reduce fuel consumption, and avoid costly delays.",
			"This leads to more efficient operations, better customer satisfaction, and significant cost savings over time.",
		],
	},
];

export const pricingDetail = [
	{
		title: "Starter Plan",
		price: "06.99",
		desc: "Minimum 500 Vehicles",
		pointers: [
			"500 GB Data",
			"#Vehicles X 2 driver licenses free",
			"$3 per driver for extra driver license",
			"Advanced analytics and dynamic dashboard",
			"15 workflow automation",
			"Advanced asset health monitoring",
			"ESG Integration with reduction opportunity analytics",
		],
	},
	{
		title: "Business Plan",
		price: "04.99",
		desc: "Minimum 500 Vehicles",
		pointers: [
			"500 GB Data",
			"#Vehicles X 2 driver licenses free",
			"$3 per driver for extra driver license",
			"Advanced analytics and dynamic dashboard",
			"15 workflow automation",
			"Advanced asset health monitoring",
			"ESG Integration with reduction opportunity analytics",
		],
	},
	{
		title: "Enterprise Plan",
		price: "03.99",
		desc: "Minimum 1500 Vehicles",
		pointers: [
			"2 TB Data",
			"#Vehicles X 2 driver licenses free",
			"$3 per driver for extra driver license",
			"Advanced analytics and dynamic dashboard",
			"Unlimited workflow automation",
			"Advanced asset health monitoring",
			"ESG integration with advanced carbon reduction opportunity analytics",
		],
	},
];

export const socialLinks = [
	{
		name: "X.com",
		link: "https://x.com/Carvach_inc",
	},
	{
		name: "Instagram",
		link: "https://www.instagram.com/carvach_inc",
	},
	{
		name: "LinkedIn",
		link: "https://www.linkedin.com/company/carvach/",
	},
];

export const navItems = [
	{
		name: "Home",
		link: "/",
	},
	{
		name: "Features",
		link: "/features",
	},
	{
		name: "Solutions",
		link: "/solution",
	},
	{
		name: "Pricing",
		link: "/pricing",
	},
	{
		name: "Contact Us",
		link: "/contact-us",
	},
];
