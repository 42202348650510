/** @format */

import React from "react";
import Layout from "../../components/Layout";
import Title from "../../components/Title";
import FeatureContainer from "../../components/Feature";
import Pricing from "../../components/Pricing";
import ContactUs from "../../components/ContactUs";

const Feature = () => {
	return (
		<Layout>
			<Title>
				<div className="text-white-txt text-[24px] font-[600] text-center py-[24px]">
					FEATURES
				</div>
			</Title>
			<div className="mb-[20px]">
				<FeatureContainer />
			</div>
			<Pricing />
			<ContactUs />
		</Layout>
	);
};

export default Feature;
