/** @format */

import React from "react";

const Testimonial = () => {
	return (
		<div className="bg-light rounded-[8px] flex flex-col px-[32px] py-[28px] md:flex md:flex-row">
			{/* till testimonial desc-----  */}
			<div className="flex flex-col justify-center items-center md:items-start ">
				<div className="bg-[#E8E8E8] py-[6px] px-[8px] rounded-[4px] text-[10px] text-[#919193] md:mb-[10px]">
					Testimonials
				</div>
				<p className="text-[32px] font-[600] text-[#616265]">
					What Customers Say
				</p>
				<p className="text-[#6B7280] text-[12px] text-center md:text-start md:mt-[20px]">
					Read what our satisfied customers have to say about our
					products/services. We take pride in providing exceptional customer
					service and value their feedback.
				</p>
			</div>
			{/* ----- testimonial ----  */}
			<div className="bg-[#fff] rounded-[12px] p-[24px] flex flex-col  mt-[24px]">
				<p className="text-[#979799] text-[16px] font-[600]">
					CarVach FleetOS has reduced our downtime by 30% and made fleet
					management effortless. The Al-driven insights are invaluable
				</p>
				<div className="flex flex-row justify-start items-center gap-4 mt-[26px]">
					<img src="/assets/testimonial.png" alt="zeroleap" />
					<div className="flex flex-col ">
						<p className="text-[16px] font-[600] text-[#7D7E7F]">
							Pradeep Rokade
						</p>
						<p className="text-[#CFCFD0] text-[12px] font-[600]">
							COO at Zeroleap
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Testimonial;
