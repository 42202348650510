/** @format */

import React from "react";
import Layout from "../../components/Layout";
import Title from "../../components/Title";
import HomeTitle from "../../components/Title/HomeTitle";
import FeatureContainer from "../../components/Feature";
import SolutionMain from "../../components/Solution/SolutionMain";
import Testimonial from "../../components/Testimonial";
import Pricing from "../../components/Pricing";
import ContactUs from "../../components/ContactUs";

function Home() {
	return (
		<Layout>
			<Title>
				<HomeTitle />
			</Title>
			<FeatureContainer />
			<SolutionMain />
			<Testimonial />
			<Pricing />
			<ContactUs />
		</Layout>
	);
}

export default Home;
