/** @format */

import React from "react";
import Button from "../Button";

const PricingCard = (props) => {
	const { title, price, desc, pointers } = props;
	return (
		<div className="flex flex-col bg-white rounded-[12px] p-[24px] ">
			<div className="text-[#808991] text-[20px] font-[600] text-center mb-[20px]">
				{title}
			</div>
			<div className="text-center text-[#000] text-[20px] font-[600]">{`$ ${price}/vehicle`}</div>
			<div className=" mt-[20px]">{desc}</div>
			<div className="text-[#808991] text-[16px]">
				<ul>
					{pointers?.map((point) => (
						<li className="list-disc ml-4">{point}</li>
					))}
				</ul>
			</div>
			<div>
				<Button text="Request a Demo" className="w-full" />
			</div>
		</div>
	);
};

export default PricingCard;
