/** @format */

import React from "react";
import Button from "../Button";

const ContactForm = () => {
	return (
		<div className="flex flex-col justify-start items-start p-[24px] gap-3">
			<div className="flex flex-col  w-full gap-2">
				<label className="text-[#A9A9A9] text-[16px] font-[600]">Name</label>
				<input
					type="text"
					name="name"
					placeholder="Name"
					className="text-[#000] py-[8px] px-[6px] rounded-[8px]"
				/>
			</div>
			<div className="flex flex-col  w-full gap-2">
				<label className="text-[#A9A9A9] text-[16px] font-[600]">
					Email address
				</label>
				<input
					type="email"
					name="email"
					placeholder="Email"
					className="text-[#000] py-[8px] px-[6px] rounded-[8px]"
				/>
			</div>
			<div className="flex flex-col  w-full gap-2">
				<label className="text-[#A9A9A9] text-[16px] font-[600]">Message</label>
				<textarea
					name="message"
					placeholder="Type your message here"
					rows={4}
					className="text-[#000] py-[8px] px-[6px] rounded-[8px]"
				/>
			</div>
			<div className="w-full">
				<Button text="Submit" type="submit" className="!w-full" />
			</div>
		</div>
	);
};

export default ContactForm;
