/** @format */

import React from "react";
import ContactForm from "./ContactForm";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
	const { pathname } = useLocation();
	return (
		<div className="text-white-txt flex flex-col border-t mt-[30px] border-[#666] border-t-[#666] md:justify-center md:px-[30%]">
			{pathname !== "/contact-us" && (
				<div className="mt-[30px] text-[24px] font-[600] text-center">
					Contact Us
				</div>
			)}
			<ContactForm />
		</div>
	);
};

export default ContactUs;
