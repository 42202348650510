/** @format */

import React from "react";
import { solutionData } from "../../constansts";
import Button from "../Button";

const SolutionPage = () => {
	return (
		<div className="bg-[#fff] rounded-[20px] flex flex-col justify-center items-center px-[32px] py-[50px] ">
			<div className="text-[24px] text-center font-[600] mb-[20px]">
				Tailored Solutions for Every Fleet
			</div>
			{solutionData?.map((solution) => (
				<div className="flex flex-row items-center mt-[40px] border border-[#E2E8F0] rounded-[8px] p-4 gap-[20px] w-full">
					{/* Image */}
					<div className="flex-shrink-0 md:w-[50%] flex justify-center">
						<img
							src={`/assets/${solution?.img}`}
							alt="img"
							className="md:h-[250px] md:w-auto"
						/>
					</div>

					{/* Text content */}
					<div className="flex-grow flex flex-col gap-2">
						<div className="text-[20px] text-[#000]">
							{solution.title}
						</div>
						<div className="text-[#808991] text-[16px]">
							<ul className="list-disc ml-4">
								{solution?.pointers?.map((point) => (
									<li key={point}>{point}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default SolutionPage;
