/** @format */

import React from "react";
import { navItems } from "../../constansts";
import { Link } from "react-router-dom";

const DesktopNav = () => {
	return (
		<div className="bg-white flex flex-row justify-between items-center h-[80px] p-[8px] px-[12px]">
			<div>
				<Link to="/">
					<img 
						src="/assets/logo.png" 
						alt="carvach_logo" 
						className="h-[240px] w-auto" // Set height and auto width
					/>
				</Link>
			</div>
			<div className="flex flex-row justify-between items-center gap-8">
				{navItems?.map((item) => (
					<Link to={item?.link} key={item?.name}>
						{item?.name}
					</Link>
				))}
			</div>
		</div>
	);
};

export default DesktopNav;
